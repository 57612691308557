import CryptoJS from "crypto-js";
let key = CryptoJS.enc.Utf8.parse("1934682168745979"); // 16位十六进制数作为秘钥
let iv = CryptoJS.enc.Utf8.parse("1934682168745979"); // 16位十六进制数作为秘钥偏移量

// 加密
export function Encrypt(str) {
  let srcs = CryptoJS.enc.Utf8.parse(str);
  let encrypted = CryptoJS.AES.encrypt(srcs, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });
  return CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
}

// 解密
export function Decrypt(decryptStr) {
  const decryptBase64Str = CryptoJS.enc.Base64.parse(decryptStr);
  const createCode = CryptoJS.lib.CipherParams.create({
    ciphertext: decryptBase64Str,
  });
  const decryptedData = CryptoJS.AES.decrypt(createCode, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return CryptoJS.enc.Utf8.stringify(decryptedData).toString();
}
