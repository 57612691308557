<template>
  <vxe-pulldown ref="pulldownRef" popup-class-name="advance-query-popup">
    <template #default>
      <vxe-button size="medium" icon="vxe-icon-arrow-down" @click="openAdvanceQueryDropDown"></vxe-button>
    </template>
    <template #dropdown>
      <div style="background-color: #fcfcfc;width: 600px;padding: 10px;border: 2px solid #afabab; border-radius: 10px">
        <vxe-form :data="dataForm" vertical title-colon style="background-color: #fcfcfc;" size="medium">
          <vxe-form-item v-for="item in config" :key="item.field" :title="item.title" span="12" :field="item.field">
            <template #default>
              <vxe-input v-if="item.type == 'text'" v-model="dataForm[item.field]" :placeholder="item.title" clearable></vxe-input>
              <el-row v-else-if="item.type == 'date'">
                <el-col :span="11">
                  <vxe-input v-model="dataForm[item.field + '-start']" placeholder="选择日期" type="date" clearable></vxe-input>
                </el-col>
                <el-col class="line" :span="2" style="text-align: center">-</el-col>
                <el-col :span="11">
                  <vxe-input v-model="dataForm[item.field + '-end']" placeholder="选择日期" type="date" clearable></vxe-input>
                </el-col>
              </el-row>
              <el-row v-else-if="item.type == 'number'">
                <el-col :span="11">
                  <vxe-input v-model="dataForm[item.field + '-start']" type="number" :class="item.suffix ? 'unit-suffix-mini' : ''" clearable :controls="false">
                    <template v-if="item.suffix" #suffix>
                      <span>{{ item.suffix }}</span>
                    </template>
                  </vxe-input>
                </el-col>
                <el-col class="line" :span="2" style="text-align: center">-</el-col>
                <el-col :span="11">
                  <vxe-input v-model="dataForm[item.field + '-end']" type="number" :class="item.suffix ? 'unit-suffix-mini' : ''" clearable :controls="false">
                    <template v-if="item.suffix" #suffix>
                      <span>{{ item.suffix }}</span>
                    </template>
                  </vxe-input>
                </el-col>
              </el-row>
              <el-row v-else-if="item.type == 'dict'">
                <vxe-select v-model="dataForm[item.field]" placeholder="请选择" clearable>
                  <vxe-option v-for="data in dictOptions[item.field]" :key="item.field + data.dictValue" :value="data.dictValue" :label="data.dictLabel"></vxe-option>
                </vxe-select>
              </el-row>
              <vxe-select v-else-if="item.type == 'select'" v-model="dataForm[item.field]" placeholder="请选择" clearable transfer>
                <vxe-option v-for="data in item.options" :key="data.value" :value="data.value" :label="data.label"></vxe-option>
              </vxe-select>
            </template>
          </vxe-form-item>
        </vxe-form>
        <div style="display: flex;justify-content: flex-end;padding-right: 10px">
          <vxe-button status="danger" icon="vxe-icon-repeat" content="清空" @click="reset()"></vxe-button>
          <vxe-button status="primary" icon="vxe-icon-search" content="查询" @click="submit()"></vxe-button>
        </div>
      </div>
    </template>
  </vxe-pulldown>
</template>
<script>
import XEUtils from 'xe-utils'
import {getDictDataList} from '@/utils'

export default {
  name: 'AdvanceQuery',
  data () {
    return {
      dataForm: {},
      dictOptions: {},
    }
  },
  created: function () {
    for (let item of this.config) {
      if (item.type == 'dict') {
        this.dictOptions[item.field] = getDictDataList(item.dictType)
      }
    }
  },
  watch: {
  },
  computed: {
  },
  props: {
    /**
     * 高级查询字段配置，示例：
     * [
     *  {field: 'name', title: '项目名称', type: 'text'},
     *  {field: 'shortName', title: '项目简称', type: 'text'},
     *  {field: 'startDate', title: '开工日期', type: 'date'},
     *  {field: 'area', title: '建筑面积', type: 'number', suffix: 'm²'}, // suffix 非必需属性
     *  {field: 'endDate', title: '字典', type: 'dict', dictType: 'mps_change_order_type'},
     *  {field: 'statusId', title: '项目状态', type: 'select', options: [{value: 1, label: '建设中'}, {value: 2, label: '已完工'}]},
     * ]
     */
    config: {
      required: true,
    },
  },
  methods: {
    openAdvanceQueryDropDown() {
      const $pulldown = this.$refs.pulldownRef
      if ($pulldown) {
        $pulldown.togglePanel()
      }
    },
    reset() {
      this.dataForm = {}
      this.$emit('query', [])
      const $pulldown = this.$refs.pulldownRef
      if ($pulldown) {
        $pulldown.hidePanel()
      }
    },
    submit() {
      this.$emit('query', this.getQueryConditions())
      const $pulldown = this.$refs.pulldownRef
      if ($pulldown) {
        $pulldown.hidePanel()
      }
    },
    getQueryConditions() {
      let conditions = []
      for (let item of this.config) {
        let condition = {}
        if (item.type == 'text') {
          if (!XEUtils.isEmpty(this.dataForm[item.field])) {
            condition = {advanceQueryField: item.field, advanceQueryValue: this.dataForm[item.field], advanceQueryOperator: 'like'}
            conditions.push(condition)
          }
        } else if (item.type == 'date' || item.type == 'number') {
          if (!XEUtils.isEmpty(this.dataForm[item.field + '-start'])) {
            condition = {advanceQueryField: item.field, advanceQueryValue: this.dataForm[item.field + '-start'], advanceQueryOperator: '>='}
            conditions.push(condition)
          }
          if (!XEUtils.isEmpty(this.dataForm[item.field + '-end'])) {
            condition = {advanceQueryField: item.field, advanceQueryValue: this.dataForm[item.field + '-end'], advanceQueryOperator: '<='}
            conditions.push(condition)
          }
        } else if (item.type == 'dict') {
          if (!XEUtils.isEmpty(this.dataForm[item.field])) {
            condition = {advanceQueryField: item.field, advanceQueryValue: this.dataForm[item.field], advanceQueryOperator: '='}
            conditions.push(condition)
          }
        } else if (item.type == 'select') {
          if (!XEUtils.isEmpty(this.dataForm[item.field])) {
            condition = {advanceQueryField: item.field, advanceQueryValue: this.dataForm[item.field], advanceQueryOperator: '='}
            conditions.push(condition)
          }
        }
      }
      return conditions
    }
  }
}
</script>
<style>
/* 将下拉容器向左平移，以与普通的搜索框对齐*/
.advance-query-popup .vxe-pulldown--wrapper {
  transform: translateX(-200px)
}
</style>