<template>
  <el-dialog width="98%" top="5vh" :visible.sync="visible" title="甘特图" :close-on-click-modal="false" :close-on-press-escape="false" v-on="$listeners">
    时间尺度：
    <el-radio-group v-model="scaleUnitModel" @input="changeScaleUnit">
      <el-radio label="year">年</el-radio>
      <el-radio label="month">月</el-radio>
      <el-radio label="week">周</el-radio>
      <el-radio label="day">日</el-radio>
    </el-radio-group>
    <div ref="gantt" class="gantt-container my-gantt" />
  </el-dialog>
</template>

<script>
import gantt from 'dhtmlx-gantt'
import 'dhtmlx-gantt/codebase/dhtmlxgantt.css'
export default {
  name: 'MyGantt',
  data () {
    return {
      visible: false,
      scaleUnitModel: this.scaleUnit,
      isPlan: true,
    }
  },
  props: {
    scaleUnit: {
      type: String,
      default: 'week'
    },
    // 是否显示实际进度
    showProgress: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    changeScaleUnit(){
      // gantt.config.scale_unit = this.scaleUnitModel
      // gantt.render();
      this.changeDateView(this.scaleUnitModel)
    },
    changeDateView(type) {
      switch(type){
        case 'year':
          gantt.config.scale_unit = "year";
          gantt.config.date_scale = "%Y年";
          gantt.config.subscales = [
            {unit: "month", step: 1, date: "%M"},
            {unit: "week", step: 1, date: "第%W周"}
          ];
          // 设置时间刻度的高度
          gantt.config.scale_height = 90; // 总高度
          break;
        case 'month':
          gantt.config.scale_unit = "month";
          gantt.config.date_scale = "%Y年%M";
          gantt.config.subscales = [
            {unit: "week", step: 1, date: "第%W周"},
            {unit: "day", step: 1, date: "%d日"}
          ];
          // 设置时间刻度的高度
          gantt.config.scale_height = 90; // 总高度
          break;
        case 'week':
          gantt.config.scale_unit = "week";
          gantt.config.date_scale = "第%W周";
          gantt.config.subscales = [
            {unit: "day", step: 1, date: "%d日"},
          ];
          // 设置时间刻度的高度
          gantt.config.scale_height = 90; // 总高度
          break;
        case 'day':
          gantt.config.scale_unit = "day";
          gantt.config.date_scale = "%M%d日";
          gantt.config.subscales = [];
          // 设置时间刻度的高度
          gantt.config.scale_height = 30; // 总高度
          break;
      }
      
      gantt.render();
    },
    /**
     * @param {*} tasks 
     * @param {*} isPlan true：显示计划进度，false：显示实际进度
     */
    init(tasks, isPlan) {
      // 统一设置颜色
      if (tasks && tasks.data) {
        tasks.data.forEach(item => {
          item.color = '#3185FC'
        })
      }

      this.isPlan = isPlan
      this.visible = true
      this.$nextTick(() => {
        gantt.clearAll()
        // 只读
        gantt.config.readonly = true
        // 自动延长时间刻度
        gantt.config.fit_tasks = true
        // 甘特图图表宽度自适应
        gantt.config.autofit = true
        gantt.config.xml_date = "%Y-%m-%d"
        
        gantt.config.step = 1
        // gantt.config.date_scale = "%Y年%M"
        // 允许拖放
        //gantt.config.drag_project = true
        gantt.i18n.setLocale('cn') // 设置中文

        // gantt.config.round_dnd_dates = true
        // gantt.config.open_split_tasks = true
        
        gantt.config.show_task_cells = true

        //  列自定义
        gantt.config.columns = [
          {
            name: 'text',
            label: '工程名称',
            tree: true,
            resize: true,
            width: "*", 
            min_width: 240,
            template: function (obj) {
              return `${obj.text}`
            },
          },
          {
            name: 'start_date',
            label: `${this.isPlan ? '计划开始' : '实际开始'}`,
            min_width: 120
          },
          {
            name: 'end_date',
            label: `${this.isPlan ? '计划结束' : '实际结束'}`,
            min_width: 120
          },
          {
            name: 'duration',
            label: '工期(天)',
            width: 110,
            align: 'center',
            template: function (obj) {
              return `${obj.duration + 1}`
            },
          },
        ]

        if (this.showProgress) {
          gantt.config.columns.push({
            name: 'progress',
            label: '施工进度',
            width: 90,
            align: 'center',
            template: function (obj) {
              return `${obj.progress * 100} %`
            },
          })
        }

        // gantt.config.tooltip_offset_x = 10
        // gantt.config.tooltip_offset_y = 30

        // 点击表头可排序
        gantt.config.sort = true

        gantt.plugins({
          tooltip: false,
          quick_info: true,// 快速信息框
          zoom: true
        })
        gantt.config.show_quick_info = true

        gantt.templates.quick_info_title = function(start, end, task){ 
          return task.text.substr(0, 24)
        }

        gantt.templates.quick_info_content = function(start, end, task){ 
          return task.text
        }
        
        Date.prototype.format = function (fmt) {
            var o = {
                "M+": this.getMonth() + 1, //月份 
                "d+": this.getDate(), //日 
                "h+": this.getHours(), //小时 
                "m+": this.getMinutes(), //分 
                "s+": this.getSeconds(), //秒 
                "q+": Math.floor((this.getMonth() + 3) / 3), //季度 
                "S": this.getMilliseconds() //毫秒 
            };
            if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
            for (var k in o)
            if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
            return fmt;
        }

        gantt.templates.quick_info_date = function(start, end, task){
          return new Date(start).format("yyyy-MM-dd") + " → " + new Date(end).format("yyyy-MM-dd")
        }

        gantt.templates.task_text = function(start, end, task){
          if (task.progress != 0) {
            if (this.showProgress) {
              return `完成 ${task.progress*100}%`;
            } else {
              return task.text;
            }
          } else {
            return task.text;
          }
        }

        gantt.config.smart_scales = true



        
        /************* 缩放配置：开始 ************/
        gantt.config.show_task_cells = true
        // 添加鼠标滚轮缩放配置
        gantt.config.scroll_on_click = false;
        gantt.config.min_column_width = 40;
        gantt.config.scale_offset_minimal = true;
        
        // 修改缩放配置
        const zoomConfig = {
          levels: [
            {
              name: "小时",
              scale_height: 60,
              min_column_width: 30,
              scales: [
                { unit: "day", step: 1, format: "%d日" },
                { unit: "hour", step: 1, format: "%H时" }
              ]
            },
            {
              name: "日",
              scale_height: 60,
              min_column_width: 50,
              scales: [
                { unit: "week", step: 1, format: "第%W周" },
                { unit: "day", step: 1, format: "%d日" }
              ]
            },
            {
              name: "周",
              scale_height: 60,
              min_column_width: 70,
              scales: [
                { unit: "month", step: 1, format: "%M" },
                { unit: "week", step: 1, format: "第%W周" }
              ]
            },
            {
              name: "月",
              scale_height: 60,
              min_column_width: 90,
              scales: [
                { unit: "year", step: 1, format: "%Y年" },
                { unit: "month", step: 1, format: "%M" }
              ]
            }
          ],
          
          useKey: "ctrlKey",
          trigger: "wheel",
          element: function(){
            return gantt.$root.querySelector(".gantt_task");
          }
        };

        // 初始化缩放功能
        gantt.ext.zoom.init(zoomConfig);
        /************* 缩放配置：结束 ************/









        // 初始化
        gantt.init(this.$refs.gantt)
        
        var removeNullProperty = function(obj){
            Object.keys(obj).forEach(item=>{
                if(item ==='parent' && (!obj[item] || obj[item] === 0))  delete obj[item]
            })
            return obj
        }
        // 去掉为null或0的parent属性
        for (const row in tasks.data) {
          removeNullProperty(row)
        }
        // 数据解析
        gantt.parse(tasks)
        
        // 时间尺度
        this.changeScaleUnit()
      })
    },
  },
}
</script>
 
<style lang="scss" scoped>
  .my-gantt {
    width: 100%;
    height: calc(100vh - 200px);
  }
  // 该甘特图组件全屏打开后，用户在关闭时非常容易误操作，直接关闭整个页签，因此，特意突出显示右上角的关闭按钮，尽可能的避免关闭时的误操作。
  //::v-deep .el-dialog__close {
    //background: url(../../../assets/img/close3.gif) left no-repeat;
    //background-size: cover;
    //width: 25px;
    //height: 25px;
  //}
  ::v-deep .gantt_task_progress {
    opacity: 0.5 !important;
  }

  /deep/ .el-radio__original {
    display: none !important; /* 隐藏原生 radio 输入，但仍然允许交互 */
  }
  
  /deep/.el-radio:focus:not(.is-focus):not(:active):not(.is-disabled) .el-radio__inner {
      box-shadow: none !important;
  }
</style>

