<template>
  <vxe-pulldown ref="xDown" transfer style="width: 100%">
    <template #default>
      <el-tooltip placement="top-start" :content="fullName" effect="light" :enterable="false" :open-delay="500">
        <vxe-input v-model="name" style="width: 100%;" size="medium" :suffix-icon="(readonly || disabled) ? '' : 'vxe-icon-search'" :placeholder="(readonly || disabled) ? '' : placeholder" @click="showDropdown" :clearable="clearable && !readonly && !disabled" @clear="onClear" readonly :disabled="disabled"></vxe-input>
      </el-tooltip>
    </template>
    <template #dropdown>
      <div class="select-dropdown">
        <vxe-table
            height="auto"
            show-overflow
            :row-config="{isHover: true, isCurrent: true, useKey: true}"
            :column-config="{resizable: true}"
            :tree-config="treeConfig"
            :data="tableData"
            size="mini"
            @cell-click="cellClickEvent">
          <vxe-column field="name" title="名称" tree-node>
            <template v-slot="{ row }">
              <span v-if="row.id == disabledId" style="color: rgba(128,128,128,0.54)">{{ row.name }}</span>
              <span v-else>{{ row.name }}</span>
            </template>
          </vxe-column>
          <vxe-column field="code" title="编号"></vxe-column>
        </vxe-table>
      </div>
    </template>
  </vxe-pulldown>
</template>
<script>
export default {
  name: 'PreWorklibSelect',
  data() {
    return {
      treeConfig: {
        rowField: 'id',
        parentField: 'pid',
        showLine: true,
        lazy: true,
        hasChild: 'hasChildren',
        loadMethod: this.loadMethod,
        iconOpen: 'vxe-icon-caret-right rotate45',
        iconClose: 'vxe-icon-caret-right',
        trigger: 'row',
        toggleMethod: this.toggleMethod
      },
      tableData: [],
      name: '',
      fullName: '',
      // 记录value的值，用于在watch value的值时判断是由于组件外部的修改（需要回显） 或是组件内部选择了某选项，双向绑定后导致的value变化（不需处理）
      localValue: '',
    }
  },
  props: {
    value: [String, Number],
    placeholder: {
      type: String,
      default: ''
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    // 此id对应的节点及其子树不可选中
    disabledId: {
      type: [String, Number],
    },
    // 默认不提供清空按钮，因为在目前后台系统的框架下，一个Long类型的字段在原本有值的情况下，无法将此字段清空并保存至数据库
    clearable: {
      type: Boolean,
      default: false
    },
    // 默认只显示节点名称,传入true则显示完整路径，
    showPNames: {
      type: Boolean,
      default: false
    },
    // 仅可选择叶节点，默认为false
    leafOnly: {
      type: Boolean,
      default: false
    }
  },
  created() {
    this.showName()
  },
  watch: {
    value: function () {
      this.showName()
    },
  },
  methods: {
    showDropdown() {
      if (!this.readonly && !this.disabled) {
        if (this.tableData.length == 0) {
          this.findChildren(0).then((data) => {
            this.tableData = data
          })
        }
        this.$refs.xDown.showPanel()
      }
    },
    cellClickEvent({row, triggerTreeNode}) {
      // 如果是折叠/展开树的操作，就不触发点击事件
      if (triggerTreeNode) {
        return
      }
      // 如果是被禁用的节点，则不允许选中
      if (this.disabledId && this.disabledId == row.id) {
        return
      }
      // 如果仅可选择叶节点，且点击的是非叶节点，则不作处理
      if (this.leafOnly && !row.isLeaf) {
        return
      } else {
        if (this.showPNames) {
          this.name = row.pnames ? row.pnames + "/" + row.name : row.name
        } else {
          this.name = row.name
        }

        this.fullName = row.pnames ? row.pnames + "/" + row.name : row.name
        this.localValue = row.id
        this.$emit('input', row.id)
        this.$emit('change', {
          id: row.id,
          name: row.name,
          pnames: row.pnames
        })
        this.$refs.xDown.hidePanel()
      }
    },
    toggleMethod({row}) {
      // 如果是被禁用的节点，则不允许展开
      if (this.disabledId && this.disabledId == row.id) {
        return false
      } else {
        return true
      }
    },
    loadMethod({row}) {
      return this.findChildren(row.id)
    },
    findChildren(pid) {
      let url = '/opm/preWorkLib/children'
      return new Promise((resolve, reject) => {
        this.$http.get(
            url,
            {
              params: {
                pid: pid
              }
            }
        ).then(({data: res}) => {
          if (res.code !== 0) {
            reject()
            return this.$message.error(res.msg)
          }
          resolve(res.data)
        }).catch(() => {
          reject()
        })
      })
    },
    onClear() {
      this.localValue = null
      this.$emit('input', null)
      this.$emit('change', {})
    },
    // 数据回显
    showName() {
      // 因组件内部选择了某选项，双向绑定后导致的value变化，所以不作处理
      if (this.value === this.localValue) {
        return
      }
      this.localValue = this.value
      // 若id为0，则特殊处理
      if (this.value == 0) {
        this.name = '无'
        this.fullName = '无'
        return
      }
      if (this.value) {
        this.$http.get('/opm/preWorkLib/' + this.value).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          if (this.showPNames) {
            this.name = res.data.pnames ? res.data.pnames + "/" + res.data.name : res.data.name
          } else {
            this.name = res.data.name
          }

          this.fullName = res.data.pnames ? res.data.pnames + "/" + res.data.name : res.data.name
        }).catch(() => {
        })
      } else {
        this.name = ''
        this.fullName = ''
      }
    }
  },
}
</script>
<style scoped>
.select-dropdown {
  height: 300px;
  background-color: #fff;
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.1);
}
</style>
