import '@/config' // public.html中配置的js代码挪到了config.js中
import Vue from 'vue'
import Element from 'element-ui'
import App from '@/App'
import i18n from '@/i18n'
import router from '@/router'
import store from '@/store'
import '@/icons'
import '@/element-ui/theme/index.css'
import '@/assets/scss/aui.scss'
import http from '@/utils/request'
import MyRadioGroup from '@/components/my-radio-group'
import DictSelect from '@/components/dict-select'
// import ProcessMultiple from '@/components/process-multiple'
// import ProcessStart from '@/components/process-start'
// import ProcessHandle from '@/components/process-handle'
import ProcessStatus from '@/components/process-status'
import ProcessRunning from '@/components/process-running'
import ProcessDetail from '@/components/process-detail'
import RegionTree from '@/components/region-tree'
import ProjectSelect from '@/components/project-select'
import MajorSelect from '@/components/major-select'
// import AuthorityProjectSelect from '@/components/authority-project-select'
import UserSelect from '@/components/user-select'
import DeptSelect from '@/components/dept-select'
import RoleSelect from '@/components/role-select'
import UnitSelect from '@/components/unit-select'
import MyUpload from '@/components/my-upload'
import MyUploadPlain from '@/components/my-upload-plain'
import MyUploadDownloadRecord from '@/components/my-upload-download-record'
import MyAvatar from '@/components/my-avatar'

import { hasPermission, getDictLabel } from '@/utils'
import cloneDeep from 'lodash/cloneDeep'

import Tinymce from '@/components/tinymce/index.vue'

import '@/utils/form-generator/styles/index.scss'
import SvgIcon from '@/utils/form-generator/components/SvgIcon'

import BaiduMap from 'vue-baidu-map'
import BaiduMapSelect from '@/components/baidu-map-select'

import global from '@/plugins/global'
import '@/plugins/vxe-table'
import echarts from '@/plugins/echarts'
import ParticipantDeptSelect from '@/components/participant-dept-select'
import ContractSelect from "@/components/contract-select"
import SubcontractorSelect from "@/components/subcontractor-select"
import PeriodSelect from "@/components/period-select"
import ExcelImport from "@/components/excel-import"
import WbsPropSelect from '@/components/wbs-prop-select'
import WbsSelect from "@/components/wbs-select"
import MyContainer from "@/components/my-container"
import ContractCascader from "@/components/contract-cascader"
import MyGantt from "@/components/my-gantt"
// 形象单元多选
import ScheduleunitMultiSelect from "@/components/scheduleunit-multi-select"
// 形象单元单选
import ScheduleunitSingleSelect from "@/components/scheduleunit-single-select"
// ifc的构件属性
import IfcProp from "@/components/ifc-prop"
// ifc模型浏览
import IfcViewer from "@/components/ifc-viewer"
// ifc模型对比浏览
import IfcCompareViewer from "@/components/ifc-compare-viewer"
import TableButton from "@/components/table-button"
import ToolbarButton from "@/components/toolbar-button"

// 物资明细多选
import MaterialMoreSelect from "@/components/material-more-select"

// 人员多选
import UserMultiSelect from "@/components/user-multi-select"
import SubItemSelect from "@/components/sub-item-select"

import '@/styles/my-icon/iconfont.css'

// 前期管理项目选择
import PreProjectSelect from '@/components/pre-project-select'
// 前期管理标准库选择
import PreWorklibSelect from '@/components/pre-worklib-select'

import SbsSelect from "@/components/sbs-select";

// 高级查询
import AdvanceQuery from '@/components/advance-query'

Vue.component('tinymce', Tinymce)
Vue.component('svg-icon', SvgIcon)

Vue.config.productionTip = false
// 弹窗默认点击遮罩改为不关闭，防止和拖拽冲突
Element.Dialog.props.closeOnClickModal.default = false

Vue.use(MyRadioGroup)
Vue.use(DictSelect)
Vue.use(RegionTree)
// Vue.use(ProcessMultiple)
// Vue.use(ProcessStart)
// Vue.use(ProcessHandle)
Vue.use(ProcessStatus)
Vue.use(ProcessRunning)
Vue.use(ProcessDetail)
Vue.use(ProjectSelect)
Vue.use(ContractSelect)
Vue.use(SubcontractorSelect)
Vue.use(ContractCascader)
Vue.use(PeriodSelect)
Vue.use(MajorSelect)
Vue.use(ParticipantDeptSelect)
// Vue.use(AuthorityProjectSelect)
Vue.use(UserSelect)
Vue.use(DeptSelect)
Vue.use(RoleSelect)
Vue.use(UnitSelect)
Vue.use(MyUpload)
Vue.use(MyUploadPlain)
Vue.use(MyUploadDownloadRecord)
Vue.use(MyAvatar)
Vue.use(ExcelImport)
Vue.use(WbsPropSelect)
Vue.use(WbsSelect)
Vue.use(SbsSelect)
Vue.use(SubItemSelect)
Vue.use(Element, {
  size: 'small',
  i18n: (key, value) => i18n.t(key, value)
})
Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: 'WSoBuUe5QmTLXwzDbDNDNf7roybm12gI' // hanson 个人申请的key
})
Vue.use(BaiduMapSelect)
Vue.use(global)
Vue.use(MyContainer)
Vue.use(MyGantt)
Vue.use(ScheduleunitMultiSelect)
Vue.use(ScheduleunitSingleSelect)
Vue.use(IfcProp)
Vue.use(IfcViewer)
Vue.use(IfcCompareViewer)
Vue.use(TableButton)
Vue.use(ToolbarButton)
Vue.use(MaterialMoreSelect)
Vue.use(UserMultiSelect)
Vue.use(PreProjectSelect)
Vue.use(PreWorklibSelect)
Vue.use(AdvanceQuery)
// 挂载全局
Vue.prototype.$http = http
Vue.prototype.$hasPermission = hasPermission
Vue.prototype.$getDictLabel = getDictLabel
Vue.prototype.$echarts = echarts

// wflow开源项目用到的组件、样式、方法
import "element-ui/lib/theme-chalk/index.css"
import "@/assets/theme.less"
import "@/assets/global.css"
import "@/assets/iconfont/iconfont.css"
import Ellipsis from '@/components/common/Ellipsis'
Vue.use(Ellipsis)

Vue.prototype.$isNotEmpty = function(obj){
  return (obj !== undefined && obj !== null && obj !== '' && obj !== 'null')
}

Vue.prototype.$deepCopy = function (obj){return JSON.parse(JSON.stringify(obj))}
// 来源于wflow的部分结束


// 保存整站vuex本地储存初始状态
window.SITE_CONFIG['storeState'] = cloneDeep(store.state)

// 用户关闭浏览器时做一些处理
window.onbeforeunload = function (e) {
  try {
    this.$http.post('/auth/oauth/logout').then(({ data: res }) => {
      // do nothing
    }).catch(() => {
      // do nothing
    })
  } catch (e) {
    // do nothing
  }
}

new Vue({
  i18n,
  router,
  store,
  beforeCreate () {
    // 全局事件总线，用于任意组件之间的通信
    // 注意：如果组件中使用了该总线（即 mounted 中this.$bus.$on），那么在组件销毁时要记得解除绑定（即在beforeDestroy中this.$bus.$off）
    Vue.prototype.$bus = this
  },
  render: h => h(App) // h 是 createElement 函数，出于性能考虑在生产环境中vue.js并不包含模板解析器，也就无法正常解析当前 main.js 中的模板，因此使用 render 方法代替 components 和 template 完成App的渲染
}).$mount('#app')
