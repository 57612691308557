export default {
  state: {
    id: 0,
    name: '',
    realName: '',
    superAdmin: 0,
    superTenant: 0,
    tenantName: '',
    prjId: '',
    typeId: '',// 非常重要（1：业主，2：参建单位，3：工区），用在零号清单、计量等功能菜单中控制项目、合同段和工区的下拉框是否显示
    prjName: '',
    headUrl: '', // 头像
    deptName: '' // 所属部门（单位）
  }
}
