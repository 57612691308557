<template>
  <el-tooltip v-if="visible" :content="btnTooltip" :disabled="disabled" placement="top" :open-delay="tooltipOpenDelay" effect="light" :hide-after="700">
    <!-- <el-button :icon="btnIcon" size="mini" @click="clickHandle()" :disabled="disabled"></el-button> -->
    <!-- 
    <vxe-button size="mini" @click="clickHandle()" :disabled="disabled" :circle="circle" :status="status" :class-name="btnCls">
      <template #icon>
        <i :class="btnIcon"></i>
      </template>
    </vxe-button>
    -->
    <vxe-button size="mini" @click="clickHandle()" :disabled="disabled" :status="btnStatus" :class-name="btnCls" :icon="btnIcon" :content="btnTooltip"></vxe-button>
  </el-tooltip>
  <div v-else style="display:inline-block; width: 28px;margin: 0 12px"></div>
</template>
<script>
// 适用于表格操作列里的按钮
export default {
  name: 'TableButton',
  data () {
    return {

    }
  },
  props: {
    /**
     * view：查看
     * add：新增
     * addChild：新增下级
     * edit：修改
     * delete：删除
     * save：保存
     * import：导入
     * export：导出
     * upload：上传
     * download：下载
     * refresh：刷新
     * copy：复制
     * lock：锁定
     * unlock：解锁
     * print：打印
     * files：附件
     * auth：授权
     * design：设计
     * deploy：部署
     * document：编辑正文
     * binding：绑定
     * suspend：挂起/暂停
     * resume：恢复运行
     * setting：设置
   */
    role: {
      type: String,
      default: ''
    },
    tooltip: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    visible: {
      type: Boolean,
      default: true
    },
    tooltipOpenDelay: {
      type: Number,
      default: 1000
    },
    status: {
      type: String,
      default: ''
    },
    circle: {
      type: Boolean,
      default: true
    },
  },
  created () {
  },
  computed: {
    btnCls() {
      return this.disabled ? 'btn-disabled' : ''
    },
    btnStatus() {
      if (this.status) {
        return this.status
      } else if (this.role == 'delete') {
        return 'danger'
      } else if (this.role == 'files') {
        return ''
      } else {
        return 'primary'
      }
    },
    btnTooltip() {
      if (this.role == 'view') {
        return this.tooltip ? this.tooltip : '查看'
      } else if (this.role == 'add') {
        return this.tooltip ? this.tooltip : '新增'
      } else if (this.role == 'addChild') {
        return this.tooltip ? this.tooltip : '新增下级'
      } else if (this.role == 'edit' || this.role == 'update') {// 兼容了this.role="update"的情况
        return this.tooltip ? this.tooltip : '修改'
      } else if (this.role == 'delete') {
        return this.tooltip ? this.tooltip : '删除'
      } else if (this.role == 'save') {
        return this.tooltip ? this.tooltip : '保存'
      } else if (this.role == 'import') {
        return this.tooltip ? this.tooltip : '导入'
      } else if (this.role == 'export') {
        return this.tooltip ? this.tooltip : '导出'
      } else if (this.role == 'upload') {
        return this.tooltip ? this.tooltip : '上传'
      } else if (this.role == 'download') {
        return this.tooltip ? this.tooltip : '下载'
      } else if (this.role == 'refresh') {
        return this.tooltip ? this.tooltip : '刷新'
      } else if (this.role == 'copy') {
        return this.tooltip ? this.tooltip : '复制'
      } else if (this.role == 'lock') {
        return this.tooltip ? this.tooltip : '锁定'
      } else if (this.role == 'unlock') {
        return this.tooltip ? this.tooltip : '解锁'
      } else if (this.role == 'print') {
        return this.tooltip ? this.tooltip : '打印'
      } else if (this.role == 'files') {
        return this.tooltip ? this.tooltip : '附件'
      } else if (this.role == 'auth') {
        return this.tooltip ? this.tooltip : '授权'
      } else if (this.role == 'design') {
        return this.tooltip ? this.tooltip : '设计'
      } else if (this.role == 'deploy') {
        return this.tooltip ? this.tooltip : '部署'
      } else if (this.role == 'document') {
        return this.tooltip ? this.tooltip : '编辑正文'
      } else if (this.role == 'binding') {
        return this.tooltip ? this.tooltip : '绑定'
      } else if (this.role == 'suspend') {
        return this.tooltip ? this.tooltip : '挂起/暂停'
      } else if (this.role == 'resume') {
        return this.tooltip ? this.tooltip : '恢复运行'
      } else if (this.role == 'setting') {
        return this.tooltip ? this.tooltip : '设置'
      } else {
        return this.tooltip ? this.tooltip : this.name
      } 
    },
    btnIcon() {
      if (this.role == 'view') {
        return 'my-icon-chakan'
      } else if (this.role == 'add') {
        return 'my-icon-plus'
      } else if (this.role == 'addChild') {
        return 'my-icon-xinzengcaidan'
      } else if (this.role == 'edit' || this.role == 'update') {// 兼容了this.role="update"的情况
        return 'my-icon-edit-square'
      } else if (this.role == 'delete') {
        return 'my-icon-del'
      } else if (this.role == 'save') {
        return 'my-icon-save'
      } else if (this.role == 'import') {
        return 'my-icon-Import'
      } else if (this.role == 'export') {
        return 'my-icon-export'
      } else if (this.role == 'upload') {
        return 'my-icon-cloud-upload'
      } else if (this.role == 'download') {
        return 'my-icon-download'
      } else if (this.role == 'refresh') {
        return 'my-icon-reloadtime'
      } else if (this.role == 'copy') {
        return 'my-icon-file-copy'
      } else if (this.role == 'lock') {
        return 'my-icon-lock'
      } else if (this.role == 'unlock') {
        return 'my-icon-unlock'
      } else if (this.role == 'print') {
        return 'my-icon-printer'
      } else if (this.role == 'files') {
        return 'my-icon-attachment'
      } else if (this.role == 'auth') {
        return 'my-icon-shouquan'
      } else if (this.role == 'design') {
        return 'my-icon-sharpicons_crop'
      } else if (this.role == 'deploy') {
        return 'my-icon-deploymentunit'
      } else if (this.role == 'document') {
        return 'my-icon-edit'
      } else if (this.role == 'binding') {
        return 'my-icon-bangding'
      } else if (this.role == 'suspend') {
        return 'my-icon-suspend'
      } else if (this.role == 'resume') {
        return 'my-icon-huifu'
      } else if (this.role == 'setting') {
        return 'my-icon-setting'
      } else {
        return this.icon
      }
    }
  },
  methods: {
    clickHandle (evt) {
      this.$emit('click', evt)
    },
  }
}
</script>
<style scoped>
.el-button {
  font-size: 17px !important;
  padding: 3px 4px !important;
}

.vxe-button {
  margin: 2px !important;
  padding: 0 3px !important;
}

.btn-disabled {
  color: rgba(128, 128, 128, 0.3) !important;
}
</style>