<template>
  <el-dialog append-to-body :visible.sync="visible" top="5vh" title="坐标位置" v-on="$listeners" width="80%" :close-on-click-modal="false" v-dialog-drag>
    <baidu-map :style="mapStyle" :center="center" :zoom="zoom" :scroll-wheel-zoom="true" :map-type="mapType" @ready="mapReadyHandler" @click="mapClickHandler">
      <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
      <bm-map-type :map-types="['BMAP_NORMAL_MAP', 'BMAP_HYBRID_MAP']" anchor="BMAP_ANCHOR_TOP_LEFT"></bm-map-type>
      <bm-city-list anchor="BMAP_ANCHOR_TOP_LEFT" :offset="{width: 10, height: 60}"></bm-city-list>
      <bm-marker :position="makerPosition"></bm-marker>
    </baidu-map>
    <el-row>
      <el-col :span="12"><label for="coordinate">经纬度：</label><span id="coordinate">{{dataForm.lng}}，{{dataForm.lat}}</span></el-col>
      <el-col :span="12"></el-col>
    </el-row>
    <template slot="footer">
      <el-button @click="visible = false">关闭</el-button>
      <el-button type="primary" @click="returnCoordinate">确定</el-button>
    </template>
  </el-dialog>
</template>

<script>
export default {
  name: 'BaiduMapSelect',
  props: {
    // 经度
    longitude: {
      type: Number,
      default: null
    },
    // 纬度
    latitude: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      visible: false,
      dataForm: {
        lng: null, // 经度
        lat: null // 纬度
      },
      mapDefaultCenter: { lng: 116.404, lat: 39.915 },
      center: { lng: 116.404, lat: 39.915 }, // 默认地点是天安门
      zoom: 12,
      // 地图类型:
      // BMAP_NORMAL_MAP，此地图类型展示普通街道视图
      // BMAP_PERSPECTIVE_MAP，此地图类型展示透视图像视图
      // BMAP_SATELLITE_MAP，此地图类型展示卫星视图
      // BMAP_HYBRID_MAP，此地图类型展示卫星和路网的混合视图
      mapType: 'BMAP_HYBRID_MAP',
      makerPosition: { lng: 0, lat: 0 },
      mapStyle: {
        width: '100%',
        height: '500px',
        'min-height': '100%'
      }
    }
  },
  watch: {
    longitude: function (val) {
      if (!this.isEmpty(val)) {
        this.makerPosition.lng = val
      }
      this.dataForm.lng = val
      this.center.lng = this.longitude || this.mapDefaultCenter.lng
      this.center.lat = this.latitude || this.mapDefaultCenter.lat
    },
    latitude: function (val) {
      if (!this.isEmpty(val)) {
        this.makerPosition.lat = val
      }
      this.dataForm.lat = val
    }
  },
  created: function () {
    this.init()
  },
  methods: {
    init () {
      this.visible = true
      this.$nextTick(() => {

      })
    },
    mapReadyHandler ({ BMap, map }) {
      if (!this.isEmpty(this.longitude)) {
        this.dataForm.lng = this.longitude
        this.dataForm.lat = this.latitude
        this.makerPosition.lng = this.longitude
        this.makerPosition.lat = this.latitude
        this.center.lng = this.longitude || this.mapDefaultCenter.lng
        this.center.lat = this.latitude || this.mapDefaultCenter.lat
      }
    },

    /**
     * 左键单击地图时触发此事件。
     * 当双击时，产生的事件序列为： click click dblclick
     */
    mapClickHandler (e) {
      this.dataForm.lng = e.point.lng
      this.dataForm.lat = e.point.lat
      this.makerPosition.lng = e.point.lng
      this.makerPosition.lat = e.point.lat
    },
    // 参数是否为空（undefined或空字符或NULL）
    isEmpty: function (param) {
      if (typeof (param) === 'undefined') {
        return true
      } else if (param === '') {
        return true
      } else if (param === null) {
        return true
      } else if (param === 0) {
        return false
      }
    },
    // 返回单击的经纬度坐标
    returnCoordinate () {
      this.visible = false
      this.$emit('setCoordinate', this.dataForm)
    }
  }
}
</script>
<style>
  /*去掉百度地图logo*/
  .BMap_cpyCtrl {
    display: none;
  }

  .anchorBL {
    display: none;
  }
</style>
