<!--附件下载记录组件-->
<template>
  <el-dialog width="50%" top="100px" v-dialog-drag :visible.sync="visible"
             :title="title" :close-on-click-modal="false"
             v-on="$listeners" :append-to-body="appendToBody">
    <el-table
      v-loading="dataListLoading"
      :data="dataList"
      highlight-current-row border
      @selection-change="dataListSelectionChangeHandle"
      @sort-change="dataListSortChangeHandle"
      :height="tableHeight" style="width: 100%;">
      <el-table-column prop="realName" label="下载人" header-align="center" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column prop="createDate" label="下载时间" header-align="center" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column prop="ip" label="IP" header-align="center" align="center"></el-table-column>
    </el-table>
    <template slot="footer">
      <el-button @click="visible = false">关闭</el-button>
    </template>
  </el-dialog>
</template>

<script>
import mixinViewModule from '@/mixins/view-module'
export default {
  name: 'MyUploadDownloadRecord',
  mixins: [mixinViewModule],
  data () {
    return {
      mixinViewModuleOptions: {
        getDataListURL: '/oss/download/list',
        getDataListIsPage: false,
      },
      title: '下载历史',
      dataForm: {
        ossId: ''
      },
      visible: false,
      tableHeight: 400,
    }
  },
  props: {
    // 附件ID
    ossId: {
      type: String,
      required: true,
      default: '-1'
    },
    // 如果该组件被嵌套在 Dialog 场景时，需要设置该属性为true
    appendToBody:{
      type: Boolean,
      default: true
    }
  },
  created () {
    let _height = window.innerHeight - 250
    this.tableHeight = _height * 0.8 < 100 ? 100 : _height * 0.8
  },
  computed: {},
  watch: {
    ossId: function (newVal) {
      if (!newVal) {
        this.ossId = '-1'
      } else {
        this.loadData()
      }
    }
  },
  methods: {
    init () {
      this.visible = true
    },
    loadData () {
      this.dataForm.ossId = this.ossId
      this.getDataList()
    }
  }
}
</script>