import axios from 'axios'
import Cookies from 'js-cookie'
import router from '@/router'
import qs from 'qs'
import { clearLoginInfo } from '@/utils'
import isPlainObject from 'lodash/isPlainObject'
import store from '@/store'
import {Encrypt} from "@/utils/encryption"

const http = axios.create({
  baseURL: window.SITE_CONFIG['apiURL'],
  // 超时时间
  timeout: 1000 * 180 * 10,
  // 开启withCredentials后，服务器才能拿到你的cookie
  withCredentials: true
})

// 令牌刷新
const requestRefreshToken = axios.create({
  baseURL: window.SITE_CONFIG['apiURL'],
  timeout: 5000,
})

// 请求拦截器中根据用户操作动态记录最新的请求时间，用于定时器中判断用户是否长时间无操作了
// 该数组定义例外url，这些url请求不算用户正常操作请求
const ignoreUrls = ['/auth/oauth/token', '/sys/online/count']

/**
 * 请求拦截
 */
http.interceptors.request.use(config => {
  config.headers['Accept-Language'] = Cookies.get('language') || 'zh-CN'
  config.headers['tenantCode'] = Cookies.get('tenantCode') || ''
  if (config.url !== '/auth/oauth/token' && config.url !== '/sys/user/openapi/dglq') {
    // 重要：如果请求头中有Authorization属性，那么后台HttpSecurity的permitAll就会失效
    config.headers['Authorization'] = 'Bearer ' + Cookies.get('access_token') || ''
  }
  config.headers['x'] = Encrypt(new Date().getTime())
  // 默认参数
  var defaults = {}
  // 防止缓存，GET请求默认带_t参数
  if (config.method === 'get') {
    config.params = {
      ...config.params,
      ...{ '_t': new Date().getTime() }
    }
  }
  if (isPlainObject(config.params)) {
    config.params = {
      ...defaults,
      ...config.params
    }
  }
  if (isPlainObject(config.data)) {
    config.data = {
      ...defaults,
      ...config.data
    }
    if (/^application\/x-www-form-urlencoded/.test(config.headers['content-type'])) {
      config.data = qs.stringify(config.data)
    }
  }
  // get序列化
  config.paramsSerializer = function (params) {
    return qs.stringify(params, { arrayFormat: 'repeat' })
  }

  // 更新用户最新的操作时间（用于定时器中判断用户是否已长时间未操作系统，从而做出是否自动退出登录的判断）
  if (!ignoreUrls.includes(config.url)) {
    store.commit('setLastOperationTime', new Date().getTime())
  }

  return config
}, error => {
  return Promise.reject(error)
})

/**
 * 响应拦截
 */
http.interceptors.response.use(response => {
  if (response.data.code === 401 || response.data.code === 10001) {
    // 20230803修改：当令牌过期后使用刷新令牌，防止用户正常操作过程中突然意外退出登录。
    // clearLoginInfo()
    // router.replace({ name: 'login' })
    // return Promise.reject(response.data.msg)
    return http.post('/auth/oauth/token', {
      refresh_token: Cookies.get('refresh_token'),
      grant_type: 'refresh_token'
    },
      {
        headers: {
          'content-type': 'application/x-www-form-urlencoded',
          // 对应数据库中的client_id、client_secret，不能随便修改
          'Authorization': 'Basic aWNsb3VkOnJlbnJlbmlv'
        }
      }
    ).then(({ data: res }) => {
      if (!res.access_token) {
        clearLoginInfo()
        router.replace({ name: 'login' })
        return Promise.reject(e)
      }
      Cookies.set('access_token', res.access_token)
      Cookies.set('refresh_token', res.refresh_token)
      response.config.headers['Authorization'] = 'Bearer ' + res.access_token
      return requestRefreshToken(response.config)
    }).catch((e) => {
      clearLoginInfo()
      
      if (Cookies.get('opm_login_source') === 'cas') {
        Cookies.remove('opm_login_source')
        // 东莞路桥门户
        window.location.href = 'http://luqiao.dgbigdata.com/sso/login'
      } else {
        router.replace({ name: 'login' })
      }
      
      return Promise.reject(e)
    })
  }
  return response
}, error => {
  console.error(error)
  return Promise.reject(error)
})

export default http
